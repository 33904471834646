import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";

const useStyles = makeStyles(theme => ({
    root: {
        display: "flex",
        alignItems: "center",
        padding: theme.spacing(2),
    },
    paper: {
        backgroundColor: theme.palette.background.paper,
        padding: theme.spacing(2),
        display: "flex",
        alignItems: "center",
        marginBottom: 12,
    },
    settingOption: {
        marginLeft: "auto",
    },
    margin: {
        margin: theme.spacing(1),
    },
    color: {
        color: theme.palette.primary,
    },
    text: {
        marginLeft: "42px",
        color: theme.palette.text.secondary,
    },
    textP: {
        marginLeft: "42px",
        color: theme.palette.text.secondary,
    },
}));

const Api = () => {
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <Container>
                <h1>Filas e Contatos</h1>
                <h2 className={classes.color}>Definir fila para o ticket</h2>
                <p>Modelo da carga para o evento</p>
                <p className={classes.text}><b>URL: </b>{process.env.REACT_APP_BACKEND_URL}/queueZdg</p>
                <p className={classes.text}><b>Metódo: </b>POST</p>
                <p className={classes.text}><b>Headers: </b>Type application/json</p>
                <p className={classes.text}><b>Body: </b> {'{"queue": 2,"ticketid":6,"token":"4289966e-107e-4791-98e1-aefeacc6864d"}'}</p>
                <br></br>
                <h2 className={classes.color}>Buscar o ticket mais atual para o contato</h2>
                <p>Modelo da carga para o evento</p>
                <p className={classes.text}><b>URL: </b>{process.env.REACT_APP_BACKEND_URL}/getUserTicket</p>
                <p className={classes.text}><b>Metódo: </b>POST</p>
                <p className={classes.text}><b>Headers: </b>Type application/json</p>
                <p className={classes.text}><b>Body: </b> {'{"msgFrom":"553591234567","token":"9c857a79-7956-454f-9abf-15f4ba2ea94e"}'}</p>                
            </Container>
        </div>
    );
};

export default Api;
