import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";

const useStyles = makeStyles(theme => ({
    root: {
        display: "flex",
        alignItems: "center",
        padding: theme.spacing(2),
    },
    paper: {
        backgroundColor: theme.palette.background.paper,
        padding: theme.spacing(2),
        display: "flex",
        alignItems: "center",
        marginBottom: 12,
    },
    settingOption: {
        marginLeft: "auto",
    },
    margin: {
        margin: theme.spacing(1),
    },
    color: {
        color: theme.palette.primary,
    },
    text: {
        marginLeft: "42px",
        color: theme.palette.text.secondary,
    },
    textP: {
        marginLeft: "42px",
        color: theme.palette.text.secondary,
    },
}));

const Api = () => {
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <Container>
                <h1>Contatos</h1>
                <h2 className={classes.color}>Ativa webhook na criação do contato</h2>
                <p className={classes.text}><b>Evento: </b>createContactService</p>
                <br></br>
                <h2 className={classes.color}>Ativa webhook na atualização do contato</h2>
                <p className={classes.text}><b>Evento: </b>updateContactService</p>
                <br></br>
                <h2 className={classes.color}>Ativa webhook na criação/atualização do contato</h2>
                <p className={classes.text}><b>Evento: </b>createOrUpdateContactService</p>
                <br></br>
                <h2 className={classes.color}>Ativa webhook na remoção do contato</h2>
                <p className={classes.text}><b>Evento: </b>deleteContactService</p>
                <br></br>
                <hr></hr>
                <h1>Controles</h1>
                <h2 className={classes.color}>Ativa webhook na definição do card no Kanban</h2>
                <p className={classes.text}><b>Evento: </b>setCardLane</p>
                <br></br>
                <h2 className={classes.color}>Ativa webhook para desaticação do Chatbot MySQL por contato</h2>
                <p className={classes.text}><b>Evento: </b>setChatBotsOff</p>
                <br></br>
                <h2 className={classes.color}>Ativa webhook para ativação do Chatbot MySQL por contato</h2>
                <p className={classes.text}><b>Evento: </b>setChatBotsOn</p>
                <br></br>
                <h2 className={classes.color}>Ativa webhook para desativação do DialogFlow por contato</h2>
                <p className={classes.text}><b>Evento: </b>setDialogFlowOff</p>
                <br></br>
                <h2 className={classes.color}>Ativa webhook para desativação do DialogFlow Áudio por contato</h2>
                <p className={classes.text}><b>Evento: </b>setDialogFlowOffAudio</p>
                <br></br>
                <h2 className={classes.color}>Ativa webhook para ativação do DialogFlow por contato</h2>
                <p className={classes.text}><b>Evento: </b>setDialogFlowOn</p>
                <br></br>
                <h2 className={classes.color}>Ativa webhook para ativação do DialogFlow Áudio por contato</h2>
                <p className={classes.text}><b>Evento: </b>setDialogFlowOnAudio</p>
                <br></br>
                <h2 className={classes.color}>Ativa webhook para desativação do ChatGPT por contato</h2>
                <p className={classes.text}><b>Evento: </b>setGPTsOff</p>
                <br></br>
                <h2 className={classes.color}>Ativa webhook para ativação do ChattGPT por contato</h2>
                <p className={classes.text}><b>Evento: </b>setGPTsOn</p>
                <br></br>
                <h2 className={classes.color}>Ativa webhook para desativação do TypeBot por contato</h2>
                <p className={classes.text}><b>Evento: </b>setTypeBotsOff</p>
                <br></br>
                <h2 className={classes.color}>Ativa webhook para ativação do TypeBot por contato</h2>
                <p className={classes.text}><b>Evento: </b>setTypeBotsON</p>
                <br></br>
                <h2 className={classes.color}>Ativa webhook para desativação do N8N por contato</h2>
                <p className={classes.text}><b>Evento: </b>setN8NsOff</p>
                <br></br>
                <h2 className={classes.color}>Ativa webhook para ativação do N8N por contato</h2>
                <p className={classes.text}><b>Evento: </b>setN8NsOn</p>
                <br></br>
                <h2 className={classes.color}>Ativa webhook na criação do protocolo</h2>
                <p className={classes.text}><b>Evento: </b>setProtocolo</p>
                <br></br>
            </Container>
        </div>
    );
};

export default Api;
