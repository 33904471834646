import React, {useEffect} from "react";
//import React from "react";
import { Avatar, CardHeader } from "@material-ui/core";
import { i18n } from "../../translate/i18n";
import axios from 'axios';

const TicketInfo = ({ contact, ticket, onClick }) => {
	
	const [tags, setTags] = React.useState([]);

	const ZDGGetTags = (async () => {
		if(ticket.status === "closed") return;
		const url = process.env.REACT_APP_BACKEND_URL + '/getZDGUserTags/' + contact.number;
		try {
		const response = await axios.get(url);
		let myArray = response.data;
			try {
				setTags(myArray)
				if(myArray === '') setTags(['Sem tags']);
			}
			catch(e){
				console.log('sem arrays')
			}
		} catch (error) {
		alert("Erro: " + error.message);
		}
	})

	useEffect(() => {
		ZDGGetTags();
		// eslint-disable-next-line
	}, []);

	return (
		<CardHeader
			onClick={onClick}
			style={{ cursor: "pointer" }}
			titleTypographyProps={{ noWrap: true }}
			subheaderTypographyProps={{ noWrap: true }}
			avatar={<Avatar src={contact.profilePicUrl} alt="contact_image" />}
			title={`${contact.name} #${ticket.id}`}
			subheader={ 
				<p>{ticket.user && `${i18n.t("messagesList.header.assignedTo")} ${ticket.user.name}`}
				<br></br>
				{ticket.status === "open" && `Tags: ` + tags}
				</p>
			}
		/>
	);
};

export default TicketInfo;
