import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Ticket from "./ticket"
import User from "./user"
import Message from "./message"
import Contact from "./contact"
import Connections from "./connections"


const useStyles = makeStyles(theme => ({
    root: {
        display: "flex",
        alignItems: "center",
        padding: theme.spacing(2),
    },
    tabs: {
        marginTop: theme.spacing(2),
    },
    paper: {
        backgroundColor: theme.palette.background.paper,
        padding: theme.spacing(2),
        display: "flex",
        alignItems: "center",
        marginBottom: 12,
    },
    settingOption: {
        marginLeft: "auto",
    },
    margin: {
        margin: theme.spacing(1),
    },
    color: {
        color: theme.palette.primary,
    },
    text: {
        marginLeft: "42px",
        color: theme.palette.text.secondary,
    },
    label: {
        fontSize: "12px"
    },
}));

const Api = () => {
    const classes = useStyles();
    const [selectedTab, setSelectedTab] = useState(0);
    const handleChange = (event, newValue) => {
        setSelectedTab(newValue);
    };

    return (
        <div className={classes.root}>
        <Container>
          <h2>Documentação para consumo dos Webhooks</h2>
          <h2 className={classes.color}>Instruções</h2>
            <p><b>Observações Importantes</b></p>
            <ul>
                <li className={classes.text}>Para definir um webhook, navegue até a opção BOT no menu lateral e escolha o item Controle BOTS - Configurações Gerais - WebHook N8N ou outro</li>
                <li className={classes.text}>Para ativar o seu webhook, navegue até a opção BOT no menu lateral e escolha o item Controle BOTS - Configurações Gerais - Liga/Desliga N8N</li>
                <li className={classes.text}>Para ativar os eventos de escuta, navegue até a opção BOT no menu lateral e escolha o item Webhooks no menu superior</li>
                <br />
            </ul>
          <h2 className={classes.color}>Eventos de Escuta Disponíveis</h2>
          <Tabs
            value={selectedTab}
            onChange={handleChange}
            indicatorColor="primary"
            textColor="primary"
            className={classes.tabs}
          >
            <Tab className={classes.label} label="Conexão" />
            <Tab className={classes.label} label="Contato" />
            <Tab className={classes.label} label="Mensagem" />
            <Tab className={classes.label} label="Usuário" />
            <Tab className={classes.label} label="Ticket" />
            
          </Tabs>
          {selectedTab === 0 && <Connections />}
          {selectedTab === 1 && <Contact />}
          {selectedTab === 2 && <Message />}
          {selectedTab === 3 && <User />}
          {selectedTab === 4 && <Ticket />}
          

        </Container>
      </div>
    );
};

export default Api;
