import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import TextMessage from "./textMessage"
import FileMessage from "./fileMessage"
import FileMessagePath from "./fileMessagePath"
import FileAudioRec from "./fileAudioRec"
import GroupUpdate from "./groupUpdate"
import Queue from "./queue"
import Connections from "./connections"


const useStyles = makeStyles(theme => ({
    root: {
        display: "flex",
        alignItems: "center",
        padding: theme.spacing(2),
    },
    tabs: {
        marginTop: theme.spacing(2),
    },
    paper: {
        backgroundColor: theme.palette.background.paper,
        padding: theme.spacing(2),
        display: "flex",
        alignItems: "center",
        marginBottom: 12,
    },
    settingOption: {
        marginLeft: "auto",
    },
    margin: {
        margin: theme.spacing(1),
    },
    color: {
        color: theme.palette.primary,
    },
    text: {
        marginLeft: "42px",
        color: theme.palette.text.secondary,
    },
    label: {
        fontSize: "12px"
    },
}));

const Api = () => {
    const classes = useStyles();
    const [selectedTab, setSelectedTab] = useState(0);
    const handleChange = (event, newValue) => {
        setSelectedTab(newValue);
    };

    return (
        <div className={classes.root}>
        <Container>
          <h2>Documentação para consumo das Apis</h2>
          <h2 className={classes.color}>Instruções</h2>
            <p><b>Observações Importantes</b></p>
            <ul>
                <li className={classes.text}>Para pegar o token da API, vá em configurações que seu token estará la, sem ele não será possivel enviar mensagens.</li>
                <li className={classes.text}>A varíavel ticketwhatsappId deve receber um número (int) com a ID da conexão ativa que será usada para envio da carga</li>
                <li className={classes.text}>O número ou id do grupo para manipulação não deve ter mascara ou caracteres especiais e deve ser composto por:</li>
                <br />
                <ol>
                    <ul>
                        <li className={classes.text}>Números privados adicionar @c.us - Ex: 5511912345678@c.us </li>
                        <li className={classes.text}>Grupos adicionar @g.us - Ex: 1235881541234848@g.us</li>
                    </ul>
                </ol>
            </ul>
          <h2 className={classes.color}>Métodos Disponíveis</h2>
          <Tabs
            value={selectedTab}
            onChange={handleChange}
            indicatorColor="primary"
            textColor="primary"
            className={classes.tabs}
          >
            <Tab className={classes.label} label="Envio de Texto" />
            <Tab className={classes.label} label="Envio de Arquivo via URL" />
            <Tab className={classes.label} label="Envio de Arquivo via Caminho" />
            <Tab className={classes.label} label="Envio de Áudio Gravado" />
            <Tab className={classes.label} label="Grupos" />
            <Tab className={classes.label} label="Filas e Contatos" />
            <Tab className={classes.label} label="Conexões" />
          </Tabs>
  
          {selectedTab === 0 && <TextMessage />}
          {selectedTab === 1 && <FileMessage />}
          {selectedTab === 2 && <FileMessagePath />}
          {selectedTab === 3 && <FileAudioRec />}
          {selectedTab === 4 && <GroupUpdate />}
          {selectedTab === 5 && <Queue />}
          {selectedTab === 6 && <Connections />}

        </Container>
      </div>
    );
};

export default Api;
