import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";

const useStyles = makeStyles(theme => ({
    root: {
        display: "flex",
        alignItems: "center",
        padding: theme.spacing(2),
    },
    paper: {
        backgroundColor: theme.palette.background.paper,
        padding: theme.spacing(2),
        display: "flex",
        alignItems: "center",
        marginBottom: 12,
    },
    settingOption: {
        marginLeft: "auto",
    },
    margin: {
        margin: theme.spacing(1),
    },
    color: {
        color: theme.palette.primary,
    },
    text: {
        marginLeft: "42px",
        color: theme.palette.text.secondary,
    },
    textP: {
        marginLeft: "42px",
        color: theme.palette.text.secondary,
    },
}));

const Api = () => {
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <Container>
                <h1>Usuário</h1>
                <h2 className={classes.color}>Ativa webhook na atualização do usuário</h2>
                <p className={classes.text}><b>Evento: </b>updateUserService</p>
                <br></br>
                <h2 className={classes.color}>Ativa webhook na remoção do usuário</h2>
                <p className={classes.text}><b>Evento: </b>deleteUserService</p>
                <br></br>
                <h2 className={classes.color}>Ativa webhook na criação do usuário</h2>
                <p className={classes.text}><b>Evento: </b>createUserService</p>
                <br></br>
            </Container>
        </div>
    );
};

export default Api;
